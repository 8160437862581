/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql, Link } from 'gatsby'
import styletron from 'gatsby-plugin-styletron'

import Header from './Header'
import './layout.css'
import { Spacing, Palette } from '../theme'
import { Label } from './Label'

const Layout = ({ children, pageTitle = null }) => {
    const css = styletron().css
    return (
        <StaticQuery
            query={graphql`
                query SiteTitleQuery {
                    site {
                        siteMetadata {
                            title
                        }
                    }
                }
            `}
            render={(data) => (
                <>
                    <Header siteTitle={data.site.siteMetadata.title} />
                    <div
                        className={css({
                            margin: `0 auto`,
                            minHeight: '100vh',
                            display: 'flex',
                            flexDirection: 'column',
                        })}>
                        {pageTitle ? (
                            <div
                                className={css({
                                    paddingTop: '64px', //`calc(56px + ${Spacing.small})`,
                                    paddingLeft: Spacing.base,
                                    paddingRight: Spacing.base,
                                    paddingBottom: Spacing.small,
                                    backgroundColor: Palette.black,
                                    '@media screen and (max-width: 680px)': {
                                        paddingTop: `calc(56px + ${Spacing.xlarge})`,
                                    },
                                })}>
                                <Label
                                    variant='title1'
                                    className={css({
                                        color: Palette.white,
                                    })}>
                                    {pageTitle}
                                </Label>
                            </div>
                        ) : null}

                        <main className={css({ flex: 1 })}>{children}</main>
                        <footer
                            className={css({
                                width: '100%',
                                minHeight: '30vh',
                                backgroundColor: Palette.black,
                            })}>
                            <div
                                className={css({
                                    maxWidth: '600px',
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    paddingTop: Spacing.base,
                                    '@media screen and (max-width: 600px)': {
                                        flexDirection: 'column',
                                        paddingLeft: Spacing.small,
                                        paddingRight: Spacing.small,
                                    },
                                })}>
                                <div className={css(FooterColumnRule)}>
                                    <Link to='/'>
                                        <Label className={css({ color: Palette.white })}>Home</Label>
                                    </Link>
                                    <Link to='/firm'>
                                        <Label className={css({ color: Palette.white })}>Firm</Label>
                                    </Link>
                                    <Link to='/attorney'>
                                        <Label className={css({ color: Palette.white })}>Attorney</Label>
                                    </Link>
                                    <Link to='/contact'>
                                        <Label className={css({ color: Palette.white })}>Contact</Label>
                                    </Link>
                                </div>
                                <div className={css(FooterColumnRule)}>
                                    <Label className={css({ color: Palette.white })}>
                                        217 South 9th Street <br />
                                        Lincoln NE, 68508 <br />
                                        USA <br />
                                        p. 402.613.4006 <br />
                                        e. bsipp@windstream.net <br />
                                    </Label>
                                </div>
                            </div>
                            <Label
                                variant='small'
                                className={css({
                                    maxWidth: '600px',
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                    color: 'rgba(255, 255, 255, 0.8)',
                                    paddingTop: Spacing.base,
                                    paddingBottom: Spacing.large,
                                    paddingHorizontal: Spacing.small,
                                })}>
                                All materials contained on this website are made available by Bradley Sipp for informational purposes only
                                and should not be construed as legal advice. The transmission and receipt of information contained on this
                                website does not form or constitute an attorney-client relationship. Persons should not act upon information
                                found on this website without first seeking professional legal counsel.
                            </Label>
                        </footer>
                    </div>
                </>
            )}
        />
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout

const FooterColumnRule = {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: Spacing.small,
    // border: `1px solid ${Palette.white}`,
    marginRight: Spacing.small,
    ':last-of-type': {
        marginRight: 0,
    },
}
