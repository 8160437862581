import React from 'react'
// import styletron from "gatsby-plugin-styletron"
import { styled, withStyle } from 'styletron-react'
import { className } from 'postcss-selector-parser'

const sharedStyles = {
    // margin: 0,
    lineHeight: '1.45',
}

const systemFont =
    "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif"

export const LabelTitle1 = styled('h1', {
    fontSize: '3rem',
    fontWeight: 600,
    fontFamily: systemFont,
    ...sharedStyles,
    '@media screen and (max-width:400px)': {
        lineHeight: '1.15',
        fontSize: '2.6rem',
    },
})

export const LabelTitle2 = styled('h2', {
    fontSize: '2rem',
    fontWeight: 600,
    fontFamily: systemFont,
    ...sharedStyles,
})

export const LabelLarge = styled('h4', {
    fontSize: '1.2rem',
    fontWeight: 500,

    ...sharedStyles,
})

export const LabelRegular = styled('p', {
    fontSize: '1rem',
    fontFamily: systemFont,
    ...sharedStyles,
})

export const LabelSmall = styled('p', {
    fontSize: '0.8rem',
    fontFamily: systemFont,
    ...sharedStyles,
})

interface LabelProps {
    variant?: 'title1' | 'title2' | 'large' | 'body' | 'small'
    children?: any
    className?: any
}

export function Label({ variant = 'body', ...rest }: LabelProps) {
    let LabelComponent
    if (variant === 'title1') {
        LabelComponent = LabelTitle1
    } else if (variant === 'title2') {
        LabelComponent = LabelTitle2
    } else if (variant === 'large') {
        LabelComponent = LabelLarge
    } else if (variant === 'body') {
        LabelComponent = LabelRegular
    } else if (variant === 'small') {
        LabelComponent = LabelSmall
    }

    return <LabelComponent {...rest} />
}
