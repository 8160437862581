export const Spacing = {
    none: 0,
    tiny: '0.5rem',
    small: '1rem',
    base: '1.5rem',
    large: '2rem',
    xlarge: '3rem',
}

export const Palette = {
    black: '#000',
    white: '#fff',

    primary: 'rgb(102, 51, 153)',
    faded: 'rgba(0, 0, 0, 0.3)',
}
