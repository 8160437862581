import React from 'react'
import { Palette, Spacing } from '../theme'
import { Label } from './Label'
import styletron from 'gatsby-plugin-styletron'
import { Link } from 'gatsby'

export default function Header({ siteTitle }: any) {
    const css = styletron().css

    return (
        <div
            className={css({
                display: 'flex',
                flexDirection: 'column',
                position: 'absolute',
                top: 0,
                zIndex: 10,
                paddingLeft: Spacing.base,
                paddingRight: Spacing.base,
                width: '100%',
            })}>
            <div
                className={css({
                    width: `100%`,
                    height: '56px',
                    backgroundColor: 'transparent',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    borderBottom: `1px solid rgba(255, 255, 255, 0.8)`,
                })}>
                <Link
                    to='/'
                    className={css({
                        textDecoration: 'none',
                        flex: 1,
                    })}>
                    <Label
                        variant='large'
                        className={css({
                            color: Palette.white,
                            fontWeight: 500,
                            '@media screen and (max-width: 400px)': { fontSize: '1rem' },
                        })}>
                        Bradley Sipp | Attorney
                    </Label>
                </Link>

                <Link to='/firm' className={css(TopLinkRule)}>
                    Firm
                </Link>
                <Link to='/attorney' className={css(TopLinkRule)}>
                    Attorney
                </Link>
                <Link to='/contact' className={css(TopLinkRule)}>
                    Contact
                </Link>

                <a href='tel:1-402-613-4006' className={css({ textDecoration: 'none' })}>
                    <Label
                        className={css({
                            color: Palette.white,
                            fontWeight: 600,
                            textDecoration: 'none',
                            '@media screen and (max-width: 400px)': { fontSize: '0.9rem' },
                        })}>
                        402.613.4006
                    </Label>
                </a>
            </div>

            <div
                className={css({
                    marginTop: Spacing.small,
                })}>
                <Link to='/firm' className={css(ExpandLinkRule)}>
                    Firm
                </Link>
                <Link to='/attorney' className={css(ExpandLinkRule)}>
                    Attorney
                </Link>
                <Link to='/contact' className={css(ExpandLinkRule)}>
                    Contact
                </Link>
            </div>
        </div>
    )
}

const TopLinkRule = {
    color: Palette.white,
    textDecoration: 'none',
    marginRight: Spacing.small,
    fontWeight: 500,
    '@media screen and (max-width: 680px)': {
        display: 'none',
    },
}

const ExpandLinkRule = {
    color: Palette.white,
    textDecoration: 'none',
    marginRight: Spacing.small,
    fontWeight: 500,
    display: 'none',
    '@media screen and (max-width: 680px)': {
        display: 'inline-block',
    },
}

/*
@media screen and (max-width: 680px)': {
    marginTop: Spacing.large,
},
*/
