import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/seo'
import { Label } from '../components/Label'
import Image from '../components/Image'
import styletron from 'gatsby-plugin-styletron'
import { Palette, Spacing } from '../theme'

const BradImage = require('../images/brad.jpg')

const AttorneyPage = () => {
    const css = styletron().css
    return (
        <Layout pageTitle='Attorney'>
            <SEO title='Home' keywords={[`Bradley Sipp`, `Bradley A. Sipp, JD`, `Attorney`]} />

            <div
                className={css({
                    paddingLeft: Spacing.base,
                    paddingRight: Spacing.base,
                    paddingTop: Spacing.large,
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    maxWidth: '720px',
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    '@media screen and (max-width: 720px)': {
                        flexDirection: 'column',
                    },
                })}>
                <div className={css({ marginBottom: Spacing.small })}>
                    <Label variant='title2'>Bradley A. Sipp, JD</Label>
                    <Label className={css({ marginTop: Spacing.small })}>
                        Bradley Sipp was born and raised in Lincoln, Nebraska and is a graduate of The University of Nebraska College of
                        Law. While in law school he won numerous moot court, client counseling and trial competitions. Since law school he
                        has tried and won numerous County Court, District Court and Appellate Court cases. He was named the 2011 Young
                        Lawyer of the Year by the Nebraska Bar Association. He went to law school in order to help people. If you have a
                        pending legal matter you need help with please call for a free, no obligation consultation.
                    </Label>
                </div>
                <img
                    src={BradImage}
                    className={css({
                        width: '230px',
                        borderRadius: '4px',
                        marginRight: Spacing.small,
                    })}
                />
            </div>
        </Layout>
    )
}

export default AttorneyPage
